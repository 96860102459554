import { Price } from 'swag-client-common/utils/price';
export function getItemPriceFromProduct({
  orderItem,
  product,
  blendedQuantity,
  screenPrice,
  totalQuantityForSharedItems,
  featureFlags,
  allOrderItems
}) {
  return Price.getPrice({
    orderItem,
    product,
    blendedQuantity,
    screenPrice,
    totalQuantityForSharedItems,
    featureFlags,
    allOrderItems
  });
}